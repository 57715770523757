import React from 'react'
import './Needhelp.css'

const Needhelp = () => {
    return (
        <>
            <div className="Need-help-container">
                <h1>Need help with using the platform?</h1>
                <p>Get qualified support from MyMoneyWays experts via online chat</p>
                <button><a style={{textDecoration:"none"}} href="mailto:info@mymoneyways.com">Contact Support</a></button>

            </div>
        </>
    )
}

export default Needhelp
